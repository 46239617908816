<script setup lang="ts">
import { VApp } from "vuetify/components/VApp";
import { VMain } from "vuetify/components/VMain";

useLocaleWatcher();

const config = useRuntimeConfig();
const sidebarOpen = ref(true);
</script>

<template>
  <VApp>
    <AppHeader
      @toggle:sidebar="sidebarOpen = !sidebarOpen"
    />
    <AppSidebar v-model="sidebarOpen" />
    <VMain>
      <div class="h-full flex flex-col">
        <AppStagingBanner v-if="config.public.API_TARGET !== 'production'" />
        <AppBreadcrumb />
        <div class="grow pb-8">
          <slot />
        </div>
        <AppFooter />
      </div>
    </VMain>
    <AppSnackbar />
  </VApp>
</template>
